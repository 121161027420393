#storybook-explorer-menu button,
#storybook-explorer-menu  a{
    color: #fff !important;
}

#storybook-explorer-searchfield{
    background: #fff;
}

.sidebar-container{
    background-color: #3a1d7a;
    border-right: 4px solid #de4a82;
}